export function importTypes($plugin) { 
const _edit = require.context('@pkg/harvester-manager/edit', true, /\.(vue|js|yaml)$/).keys();

_edit.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('edit', name, () => import(/* webpackChunkName: "edit" */ `@pkg/harvester-manager/edit/${ name }`)); // eslint-disable-line no-undef
});
const _list = require.context('@pkg/harvester-manager/list', true, /\.(vue|js|yaml)$/).keys();

_list.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('list', name, () => import(/* webpackChunkName: "list" */ `@pkg/harvester-manager/list/${ name }`)); // eslint-disable-line no-undef
});
const _machine_config = require.context('@pkg/harvester-manager/machine-config', true, /\.(vue|js|yaml)$/).keys();

_machine_config.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('machine-config', name, () => import(/* webpackChunkName: "machine-config" */ `@pkg/harvester-manager/machine-config/${ name }`)); // eslint-disable-line no-undef
});
const _models = require.context('@pkg/harvester-manager/models', true, /\.(vue|js|yaml)$/).keys();

_models.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('models', name, () => require(`@pkg/harvester-manager/models/${ name }`)); // eslint-disable-line no-undef
});
const _l10n = require.context('@pkg/harvester-manager/l10n', true, /\.(vue|js|yaml)$/).keys();

_l10n.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('l10n', name, () => import(/* webpackChunkName: "[request]" */ `@pkg/harvester-manager/l10n/${ name }.yaml`)); // eslint-disable-line no-undef
});
};
